.welcome-section-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.welcome-section {
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  text-align: center;
  padding: 1rem;
  border-radius: 8px;
  width: 100%;
  height: auto;
  margin-left: 1rem;
  margin-right: 1rem;
}

.spacer {
  width: 90%;
  margin: auto;
}

.welcome-section > h1 {
  font-family: QuickSand;
  font-size: 30px;
  font-weight: 600;
}

.welcome-section > span {
  font-family: ShaiFontai !important;
  font-size: 80px;
  line-height: 1;
}

.motto-text {
  font-family: QuickSand;
  font-size: 20px;
}

.button-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.button-wrapper > .btn {
  width: 150px;
  margin: 0 2rem;
  width: 150px;
  margin: 0 2rem;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  border-radius: 25px;
}

.button-wrapper > .btn:hover {
  background: rgba(135, 69, 120, 1);
  color: white !important;
}

.welcome-section > .moto-text {
  font-family: KushanScript;
  font-size: 20px;
}

@media screen and (max-width: 1024px) {
  .welcome-section > h1 {
    font-size: 30px;
  }
  .welcome-section > span {
    font-size: 60px;
  }
  .button-wrapper > .btn {
    font-size: 18px;
  }
}

@media screen and (max-width: 991px) {
  .welcome-section > h1 {
    font-size: 30px;
  }

  .welcome-section > span {
    font-size: 40px;
  }

  .button-wrapper {
    flex-direction: row;
  }

  .button-wrapper > .btn {
    margin: 1rem 1rem;
  }
  .welcome-section-wrapper {
    width: 100%;
  }
  .button-wrapper > .btn {
    font-size: 16px;
  }
  .motto-text {
    font-family: QuickSand;
    font-size: 16px;
  }
}
@media screen and (max-width: 820px) {
  .welcome-section > h1 {
    font-size: 30px;
  }

  .welcome-section > span {
    font-size: 60px;
  }

  .button-wrapper {
    flex-direction: row;
  }

  .button-wrapper > .btn {
    margin: 1rem 1rem;
  }
  .welcome-section-wrapper {
    width: 100%;
  }
  .button-wrapper > .btn {
    font-size: 16px;
  }
}

@media screen and (max-width: 768px) {
  .welcome-section > h1 {
    font-size: 30px;
  }

  .welcome-section > span {
    font-size: 55px;
  }

  .button-wrapper {
    flex-direction: row;
  }

  .button-wrapper > .btn {
    margin: 1rem 1rem;
  }
  .welcome-section-wrapper {
    width: 100%;
  }
  .button-wrapper > .btn {
    font-size: 16px;
  }
}

@media screen and (max-width: 575px) {
  .welcome-section > h1 {
    font-size: 20px;
  }

  .welcome-section > span {
    font-size: 50px;
  }
  .button-wrapper > .btn {
    font-size: 14px;
    margin-top: 0.5rem;
  }
  .motto-text {
    font-family: QuickSand;
    font-size: 16px;
  }
}

.arrow-icon-wrapper {
  position: absolute;
  bottom: 9rem;
  left: 50%;
  transform: translateX(-50%);
  animation: bounce 2s infinite;
  border-radius: 50%;
  border: 2px solid white;
}

@media screen and (max-width: 428px) and (max-width: 926px) {
  .arrow-icon-wrapper {
    bottom: 12rem;
  }
  .welcome-section > h1 {
    font-size: 20px;
  }
}

@keyframes bounce {
  0% {
    transform: translateX(-50%) translateY(0);
  }
  50% {
    transform: translateX(-50%) translateY(20px);
  }
  100% {
    transform: translateX(-50%) translateY(0);
  }
}
