.footer-section-outer {
  width: 100%;
  background-color: transparent;
  padding-bottom: 5rem;
}

.footer-restaurant-name {
  font-family: ShaiFontai;
  font-size: 35px;
  color: red;
}

.footer-section-inner {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  padding-bottom: 0;
}

.footer-brand {
  width: 100px;
  height: auto;
  margin: 0 -1.5rem 0 0;
}

.footer-copyright {
  font-size: 18px;
  color: white;
  padding-right: 1rem;
}
.footer-copyright > a {
  font-weight: bold;
}

.footer-social-icons {
  display: flex;
  flex-direction: row;
  width: auto;
  justify-content: center;
  align-items: center;
}

.social-icon {
  background: rgba(135, 69, 120, 1);
  border-radius: 50%;
  padding: 0.2rem;
  margin: 0 0.5rem 0 0;
  transition: all 0.2s ease-in-out;
}

.social-icon:hover {
  background: white;
  color: black !important;
}
.social-icon > a:hover {
  color: black !important;
}
.social-icon > a > .MuiSvgIcon-root {
  margin-top: -0.2rem;
}

.footer-divider {
  display: none;
}

.footer-impressum {
  text-align: center;
  padding: 0.7rem;
  background: rgba(135, 69, 120, 1);
  width: 150px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: auto;
  height: 50px;
  border-radius: 25px;
  margin-bottom: 1rem;
}

.footer-impressum > a {
  text-decoration: none;
}

.brand-title {
  font-family: ShaiFontai;
  font-size: 70px;
  color: white;
}

@media (max-width: 991px) {
  .footer-copyright {
    font-size: 16px;
    text-align: center;
    padding: 1rem;
  }

  .footer-section-inner {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1rem;
  }

  .footer-brand {
    max-width: 100%;
    height: auto;
    margin: auto;
  }

  .footer-divider {
    display: block;
    background: white;
    color: white;
    height: 1px;
    width: 50%;
    padding: 0;
    margin: 0.5rem 0 1rem 0;
  }

  .social-icon {
    width: 100%;
  }

  .footer-section-outer {
    padding-bottom: 6rem;
  }
}
