.scroll-banner-outer {
  height: 400px;
  overflow: hidden;
  position: relative;
}

.scroll-banner-outer > img {
  height: 100vh;
}

.scroll-banner-outer:hover:before {
  height: 0;
}

@media (max-width: 1440px) {
  .scroll-banner-outer > img {
    height: auto;
  }
}

@media (max-width: 991px) {
  .scroll-banner-outer {
    height: auto;
  }
  .scroll-banner-outer > img {
    height: 100%;
  }
}
