.motivation-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.motivation-text {
  background-color: #201a1b;
  color: white;
  padding: 2rem;
  flex: 1;
  margin: auto;
  font-size: 20px;
}

.motivation-image {
  flex: 1;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.motivation-image img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 8px;
}
.motivation-text > h2 {
  font-family: KushanScript;
  padding: 1rem 1rem;
  font-size: 40px;
}

.motivation-section-second {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  width: 100%;
}

.motivation-text > h4 {
  padding: 1rem 0 1rem 0;
  font-weight: bold;
}
.discount-text {
  color: red;
  font-weight: bold;
}

.foodMenu-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.foodMenu-buttons > .btn {
  margin: 0 1rem;
  border-radius: 25px;
}

.foodMenu-buttons > .btn:hover {
  background: rgba(135, 69, 120, 1);
  color: white !important;
}
.restaurant-name {
  font-family: ShaiFontai;
  font-size: 30px;
  color: red;
}

@media (max-width: 1200px) {
  .motivation-section {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
  }
  .motivation-section-second {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
  }
  .motivation-text {
    background-color: #201a1b;
    color: white;
    padding: 1rem;
    flex: 1;
    margin: auto;
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .motivation-section {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
  }
  .motivation-text {
    background-color: #201a1b;
    color: white;
    padding: 1rem;
    flex: 1;
    margin: auto;
    font-size: 16px;
  }
  .motivation-section-second {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
  }
  .discount-text {
    text-align: center;
  }
  .foodMenu-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}
