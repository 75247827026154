.gallery-section-outer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.gallery-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1440px; /* set a maximum width for the gallery */
  margin: 2rem auto; /* center the gallery horizontally */
}

.gallery-image {
  position: relative;
  flex-basis: calc((100% - 80px) / 4);
  margin: 10px;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
}

.gallery-image > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.gallery-image .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(135, 69, 120, 1);
  opacity: 0;
  transition: opacity 0.5s ease;
}

.gallery-image:hover .overlay {
  opacity: 1;
}

.gallery-image::before {
  content: "+";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5rem;
  color: white;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  z-index: 1;
}

.gallery-image:hover::before {
  opacity: 1;
}

@media (max-width: 991px) {
  .gallery-image {
    flex-basis: calc(
      (100% - 40px) / 2
    ); /* set two images per row on smaller screens */
    margin: 5px; /* reduce the space between the images */
  }
}
