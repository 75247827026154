.dataSecurityWraper {
  width: 100%;
  height: 100%;
  margin-top: 6rem;
}

.dataSecurityContent {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: white;
}
