.newsBanner {
  position: fixed;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 10px;
  text-align: center;
  width: 100%;
  color: white;
  padding: 1rem;
}

@media (max-width: 991px) {
  .newsBanner > h5 {
    font-size: 16px;
  }
}
