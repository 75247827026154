/********** Custom fonts **********/

/*** Logo fonts ***/
@font-face {
  font-family: "ShaiFontai";
  src: url("../../assets/fonts/SF_Shai_Fontai.ttf");
  font-weight: 400px;
}
/*** Section title fonts ***/
@font-face {
  font-family: "KushanScript";
  src: url("../../assets/fonts/KaushanScript-Regular.otf");
  font-weight: 400px;
}
/*** default text fonts ***/
@font-face {
  font-family: "QuickSand";
  src: url("../../assets/fonts/Quicksand-Regular.ttf");
  font-weight: 400px;
}

/********** Default CSS **********/
:root {
  --black-color: #000000;
  --white-color: #ffffff;
  --lightgrey-color: #cccccc;
  --background-color: #201a1b;
  --gold-color: #d6af5c;
  --dark-red: #9e2c28;
  --button-color: rgba(135, 69, 120, 1);
  --hover-color: rgba(135, 69, 120, 1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden !important;
  scroll-behavior: auto;
}

body {
  overflow-x: hidden !important;
  position: relative;
  font-family: QuickSand;
  font-weight: 400;
  background-color: #201a1b;
}

a {
  color: white !important;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

.horizontal-divider {
  height: 35px;
  width: 1px;
  margin: 0 10px;
  border-left: 1px solid #fff;
}

.homepage-banner {
  background-image: url("../../assets/images/moon-banner.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  top: 0;
  left: 0;
  height: 100vh;
  position: relative;
}

.section-title {
  font-family: KushanScript;
  color: white !important;
  font-size: 40px;
}

.seperator {
  height: 30px;
}

.scroll-top-button {
  position: fixed;
  bottom: 5rem;
  right: 20px;
  z-index: 9999;
  cursor: pointer;
  background: rgba(135, 69, 120, 0.8);
  color: #ffff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scroll-top-button:hover {
  background-color: rgba(135, 69, 120, 1);
}

@media (max-width: 991px) {
  .scroll-top-button {
    bottom: 6.5rem;
  }
}
