.navbar {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px 0 20px 0;
}

.navbar.scrolled {
  background: rgba(0, 0, 0, 0.8);
}

.navbar-brand {
  max-width: 150px;
  height: 64px;
  font-family: ShaiFontai;
  font-size: 60px;
  padding: 0;
  margin-top: -2rem;
}

.navbar-toggler {
  border: none;
  background-color: transparent;
  outline: none;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' strokeLinecap='round' strokeMiterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.white-toggle {
  background: transparent;
  color: white;
  outline: none; /* Remove focus outline */
  transition: color 0.3s ease-in-out; /* Add transition for the color property */
  font-size: 24px;
  position: relative;
}

.white-toggle.expanded .toggle-icon {
  animation: fadeToggle 0.3s ease-in-out;
}

@keyframes fadeToggle {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.toggle-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
}

.nav-link {
  font-size: 21px;
  font-family: QuickSand;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0 1rem;
  position: relative; /* Required for ::after pseudo-element */
  color: #000; /* Default color */
  text-decoration: none; /* Remove default underline */
  transition: all 0.2s ease-in-out; /* Smooth transition */
}

.navbar-nav > .nav-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -2px;
  left: 0;
  background-color: rgba(135, 69, 120, 1);
  transition: all 0.2s ease-in-out; /* Smooth transition */
}
.navbar-nav > .nav-link:hover::after {
  width: 100%;
}
.social-icons > .nav-link::after {
  display: none;
}

.nav-link:hover {
  color: rgba(135, 69, 120, 1) !important;
}

.social-icons {
  align-items: center;
}

.social-icons > .nav-link {
  padding: 0;
  margin: 0.5rem;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: #201a1b !important;
  transition: all 0.5s ease-in-out; /* added transition property */
}

.social-icons .nav-link:hover {
  background: rgba(135, 69, 120, 1);
  color: white !important;
  transition: all 0.5s ease-in-out; /* added transition property */
}

/* Hintergrund des Dropdown-Menüs */
.dropdown-menu {
  background: rgba(0, 0, 0, 0.8);
}

/* Textfarbe des Dropdown-Menüs */
.dropdown-menu a {
  color: white;
}

/* Hover-Effekt für Dropdown-Links */
.dropdown-menu a:hover {
  background-color: rgba(135, 69, 120, 1);
}

@media (min-width: 1025px) and (max-width: 1200px) {
  .nav-link {
    font-size: 16px;
  }
}

@media (max-width: 1024px) {
  .nav-link {
    font-size: 16px;
    font-family: QuickSand;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0.5rem;
  }
  .social-icons .MuiSvgIcon-root {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .nav-link {
    font-size: 16px;
  }
  .navbar-collapse {
    display: block;
    justify-content: space-between;
    align-items: center;
    padding-top: 1rem;
  }

  .navbar-nav {
    width: 100%;
    justify-content: center;
  }

  .nav-item {
    width: auto;
  }

  .social-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }

  .social-icons .nav-link {
    margin: 0;
    padding: 0;
    margin: 0 1rem;
  }

  .social-icons .nav-link:hover {
    background-color: transparent;
  }

  .social-icons .MuiSvgIcon-root {
    font-size: 16px;
  }
  .navbar-brand {
    margin-top: -1.3rem;
  }
}

@media (max-width: 352px) {
  .social-icons {
    display: none;
  }
}
