.contact-section-outer {
  width: 100%;
}

.contact-section-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  padding: 0;
  margin-bottom: 2rem;
}

.contact-section-outer > .section-title {
  padding: 2rem 0 0 0;
  text-align: center;
}
.contact-icons {
  width: 100%;
  height: 100%;
  display: block;
}

.contact-icon {
  display: flex;
  align-items: center;
  background-color: white;
  width: 403px;
  height: 80px;
  padding: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  margin: 0 0 1rem 0;
  transition-duration: 0.5s;
  transition: background-color 0.3s ease-in-out;
}

.contact-icon .icon {
  margin-right: 1rem;
  border-radius: 50%;
  border: 2px solid #201a1b;
  padding: 1rem;
  color: #201a1b !important;
}

.contact-icon .title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  line-height: 1;
}

.contact-icon .description {
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  font-weight: bold;
}

.contact-text {
  color: white;
  text-align: left;
  padding: 1rem;
  max-width: 600px;
  padding: 2rem;
  font-size: 18px;
}

.contact-icon:hover {
  background: rgba(135, 69, 120, 1);
  color: white;
  transition: ease-in-out;
}

.multiNumber {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
}

@media (max-width: 991px) {
  .contact-section-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    padding: 0rem;
  }
  .contact-text {
    text-align: left;
    font-size: 16px;
  }
}
